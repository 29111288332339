.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #000000;
  font-family: 'AvenirNext-Bold', sans-serif;
  color: #ffffff;
}

.divider {
  border: none; /* Removes the default border */
  height: 2px; /* Sets the height of the divider */
  width: 30%; /* Adjust the width as needed */
  background-color: #333; /* Sets the divider color */
  margin: 10px auto; /* Centers the divider and adds some vertical space */
}

header, section {
  width: 80%;
  max-width: 600px; /* Adjust based on your preference */
}

h1, h2, h3, p, a {
  text-align: center;
}

/* Custom font styles */
h1, h2, h3 {
  font-family: 'AvenirNext-Heavy', sans-serif;
  color: '#ffffff';
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
